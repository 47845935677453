var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "docsify-menu", class: { "docsify-menu-open": _vm.open } },
    [
      _c(
        "div",
        { staticClass: "docsify-search" },
        [
          _c("a-auto-complete", {
            staticStyle: { width: "100%" },
            attrs: {
              placeholder: "Search",
              "allow-clear": "",
              "data-source": _vm.dataSource,
              "option-label-prop": "text",
            },
            on: { select: _vm.onSelect },
            model: {
              value: _vm.searchValue,
              callback: function ($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "docsify-menu-list" },
        [
          _c("div", { staticClass: "docsify-title" }, [_vm._v("docsify")]),
          _c("drag-manage", {
            attrs: { "can-drag": _vm.canDrag, list: _vm.list },
          }),
        ],
        1
      ),
      _c(
        "a",
        {
          staticClass: "docsify-menu-fold",
          class: { "docsify-fold-open": _vm.open },
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [
          _c("a-icon", {
            attrs: { type: _vm.open ? "menu-fold" : "menu-unfold" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }