<template>
  <div class="docsify-menu" :class="{ 'docsify-menu-open': open }">
    <div class="docsify-search">
      <a-auto-complete
        v-model="searchValue"
        placeholder="Search"
        style="width: 100%"
        @select="onSelect"
        allow-clear
        :data-source="dataSource"
        option-label-prop="text"
      />
    </div>
    <div class="docsify-menu-list">
      <div class="docsify-title">docsify</div>
      <drag-manage :can-drag="canDrag" :list="list" />
    </div>
    <a class="docsify-menu-fold" :class="{ 'docsify-fold-open': open }" @click="open = !open">
      <a-icon :type="open ? 'menu-fold' : 'menu-unfold'" />
    </a>
  </div>
</template>

<script>
import DragManage from './drag-manage'
export default {
  components: {
    DragManage,
  },
  computed: {
    dataSource() {
      return this.searchValue
        ? this.flatList.filter(({ text }) => {
            return text.includes(this.searchValue)
          })
        : []
    },
  },
  props: {
    canDrag: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      open: true,
      searchValue: '',
      flatList: '',
      flatMap: null,
      list: [
        {
          id: 1,
          title: 'Getting started',
          children: [
            {
              id: 2,
              title: 'Quick start',
            },
            {
              id: 3,
              title: 'Writing more pages',
            },
            {
              id: 4,
              title: 'Custom navbar',
            },
            {
              id: 5,
              title: 'Cover page',
            },
          ],
        },
        {
          id: 6,
          title: 'Customization',
          children: [
            {
              id: 7,
              title: 'Configuration',
            },
            {
              id: 8,
              title: 'Themes',
            },
            {
              id: 9,
              title: 'List of Plugins',
            },
            {
              id: 10,
              title: 'Write a Plugin',
            },
            {
              id: 11,
              title: 'Markdown configuration',
            },
            {
              id: 12,
              title: 'Language highlighting',
            },
          ],
        },
        {
          id: 13,
          title: 'Guide',
          children: [
            {
              id: 14,
              title: 'Deploy',
            },
            {
              id: 15,
              title: 'Helpers',
            },
            {
              id: 16,
              title: 'Vue compatibility',
            },
            {
              id: 17,
              title: 'CDN',
            },
            {
              id: 18,
              title: 'Offline Mode(PWA)',
            },
            {
              id: 19,
              title: 'Server-Side Rendering(SSR)',
            },
            {
              id: 20,
              title: 'Embed Files',
            },
          ],
        },
        {
          id: 21,
          title: 'Awesome docsify',
        },
        {
          id: 22,
          title: 'Changlog',
        },
      ],
    }
  },
  created() {
    this.flatList = this.getList(this.list).flat(Infinity)
    this.flatMap = new Map(this.flatList.map(({ value, text }) => [value, text]))
    console.log({ 'this.flatList': this.flatList })
    console.log({ 'this.flatMap': this.flatMap })
  },
  methods: {
    onSelect(value) {
      this.searchValue = this.flatMap.get(value)
      console.log({ 'this.searchValue': this.searchValue })
    },
    getList(list) {
      if (!list.length) return []
      return list.map(({ id: value, title: text, children }) =>
        children ? [{ value: String(value), text }, ...this.getList(children)] : [{ value: String(value), text }]
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.docsify-menu-open {
  width: 300px !important;
  padding-right: 4px;
  border-right: 1px solid #eee;
}

.docsify-menu {
  width: 0px;
  height: calc(100vh - 112px);
  overflow-y: auto;
  transition: width 0.3s;

  .docsify-search {
    margin-bottom: 20px;
    padding: 6px;
    border-bottom: 1px solid #eee;

    /deep/ .ant-input {
      border-color: white;
    }

    /deep/ .ant-input:focus {
      border-color: #7caaeb;
    }
  }

  .docsify-menu-list {
    .docsify-title {
      margin: 0 auto 1rem;
      font-size: 1.5rem;
      font-weight: 300;
      margin-left: 113px;
    }
  }

  .docsify-menu-fold {
    position: absolute;
    bottom: 0;
    padding: 10px 0 10px 20px;
    background: hsla(0, 0%, 100%, 0.8);
    font-size: 18px;
    width: 40px;
    z-index: 1;
  }

  .docsify-fold-open {
    width: 300px;
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.docsify-menu:hover {
  padding-right: 0px;
}

.docsify-menu:hover::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
</style>